body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Atten New";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Atten New";
  src: local("Atten New"), url("./assets/AttenNewBold.woff") format("woff");
  font-weight: normal;
}

.default-txt-field {
  width: 100%;
  font-weight: normal;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.14);
  box-sizing: border-box;
  border-radius: 5px;
  color: white;
  height: 50px;
  padding: 0 9px;
}
.default-txt-field.desktop {
  height: 70px;
  padding: 0 14px;
}
.default-txt-field::placeholder {
  color: rgba(231, 225, 197, 0.31);
}
.default-txt-field:focus {
  outline: none;
}

.default-button {
  background: #c57a1e;
  border-radius: 50px;
  height: 42px;
  width: 100%;
  color: white;
}
.default-button-bordered {
  border: 1.5px solid #c57a1e;
  border-radius: 30px;
  color: #c57a1e;
}
:is(.default-button, .default-button-bordered).desktop {
  height: 50px;
}
