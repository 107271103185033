html * {
  font-family: 'Atten New';
}

.App {
  text-align: center;
}
.main {
  width: 50%;
  margin: 50px auto;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.has-search .form-control {
  padding-left: 4.375rem;
  height: 4.5rem;

  /* width: 769px; */
}
.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 5.375rem;
  height: 2.375rem;
  line-height: 4.35rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.under {
  z-index: -1;
}
.over {
  z-index: -1;
}
.TeamMobileHeading {
  font-family: Atten New;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  /* background: black !important; */
  /* ~sahil, commented above and using `background with some opacity`(see below line) to get transparent background in the popup delete popup in team credential page. */
  background: rgba(0, 0, 0, 0.8) !important;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0.4;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}
.cardMobile {
  /* width: 156px;
  height: 110px; */
  align-items: center;
  justify-content: center;
  border: 1px solid #dfe0e6;
  box-sizing: border-box;
  border-radius: 10px;
}

.text_Card_Title {
  width: 256px;
  height: 28px;
  left: 133px;
  top: 473px;
  font-family: 'Atten New';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  /* or 150% */
  display: flex;
  align-items: center;
  color: #222222;
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  /* background: black !important; */
  /* ~sahil, commented above and using `background with some opacity`(see below line) to get transparent background in the popup delete popup in team credential page. */
  background: rgba(0, 0, 0, 0.8) !important;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0.4;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}
.card_name {
  /* font-family: Rubik; */
  font-style: normal;
  font-weight: bold;
  /* font-size: 30px; */
  font-size: 18px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #222222;
}
.custom-ui {
  /* width: 484px; */
  /* ^^ commented to fix the delete prompt(Team credential page - delete action) using flex box, yo! ~sahil */
  display: flex;
  flex-direction: column;
  align-items: center;

  /* height: 259.86px; */
  left: 478px;
  top: 359px;
  width: 95vw;

  background: #000000;
  box-shadow: 0px 10px 40px rgba(188, 147, 107, 0.2);
  border-radius: 11.2302px;

  padding: 2rem;
  /* ^^ ~sahil, added to give padding to contents inside the alert cancel delete buttons in deleting ticket prompt. */
}
.overlay_our {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: transparent;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}
.ui {
  /* width: 350.43px; */
  max-width: 310px;
  padding: 0 1rem;
  line-height: 24px;
  /* height: 78.31px; */
  left: 544.97px;
  top: 401.86px;

  /* margin: auto; */
  /* ^^~sahil, we don't need this now. */
  font-family: Atten New;
  font-style: normal;
  font-weight: bold;
  /* font-size: 26px; */
  font-size: 1.25rem;
  line-height: 24px;
  /* or 169% */
  /* padding-top: 9%; */
  text-align: center;

  /* color: #ffffff; */
  color: white;
}
#inputID::placeholder {
  color: #ff0000;
  opacity: 1;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(190 190 190) !important;
  opacity: 1; /* Firefox */
}
.form-control:not(active) {
  color: white;
}

.form-control:focus {
  color: black;
}

.card {
  width: 171px;
  height: 150px;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfe0e6;
  box-sizing: border-box;
  border-radius: 10px;
}
.selected_card {
  width: 171px;
  height: 150px;
  align-items: center;
  justify-content: center;
  border: 1px solid #0085ff;
  box-sizing: border-box;
  border-radius: 10px;
}
.text_card {
  font-family: 'Atten New';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  /* or 100% */
  align-items: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.circle {
  clip-path: circle(43% at 50% 50%);
  height: 2.5rem;
  width: 2.5rem;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #e7e1c5 !important;
}
.form-control:focus {
  border-color: '#010101' !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
