.TotalsView {
  border-radius: 10px;
  background: black;
  overflow: hidden;
  margin: 10px 0;
}

.TotalsView img {
  object-fit: cover;
}
.TotalsView .contents {
  z-index: 1;
}
.TotalsView .separator {
  background: #ffffff;
  opacity: 0.2;
  width: 2px;
}
.TotalsView .total-content-div .number-label {
  color: #e7e1c5;
}
.TotalsView .total-content-div .title-label {
  color: #ffffff;
}
