.TextField .text-label {
  font-weight: bold;
  font-size: 18px;
  color: #e7e1c5;
}

/* .TextField input {
  width: 100%;
  font-weight: normal;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.14);
  box-sizing: border-box;
  border-radius: 5px;
  color: white;
}

.TextField input::placeholder {
  color: rgba(231, 225, 197, 0.31);
}

.TextField input:focus {
  outline: none;
} */

.TextField .important {
  color: #ff8b9a;
}
