.team-name {
  height: 60px;
  background-color: #151515;
  color: #e7e1c5;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
}
.validation-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
}

.close_button {
  display: flex;
}

.credentials {
  display: flex;
}
.star {
  color: red;
}
.customSelect .css-yk16xz-control {
  /* height: 60px; */
  /* ^^ That used to specify the height of TeamName text field, and we don't want static height here.~sahil */
  background-color: #151515;
  color: #e7e1c5;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 99%;
  /* margin-left: 10px; */
  /* Assiging padding directly to the container is a good option instead of giving marign to all individual elements on the container children. */
}
.customSelect .css-1pahdxg-control {
  /* height: 60px; */
  /* ^^ That used to specify the height of TeamName text field when we have that text field active(like we selected a field there), and we don't want static height here.~sahil */
  background-color: #151515;
  color: #e7e1c5;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 99%;
  /* margin-left: 10px; */
  /* ^^ not needed now, ~sahil */

  height: 48px;
  /* ^^That fixes the height of the TeamName field when the field is clicked(i.e., the dropdown is open). */
}

.customSelect .css-26l3qy-menu {
  background-color: #151515;
  color: #e7e1c5;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.css-9gakcf-option {
  background-color: #c57a1e !important;
  color: hsl(0, 0%, 100%);
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
.css-9gakcf-option:active {
  background-color: #c57a1e !important;
}
.customSelect .css-b8ldur-Input {
  color: white;
}

.css-yk16xz-control {
  height: 48px;
  /* ^^ That sets the height of TeamName text field. */
}

.customSelect .css-1n7v3ny-option {
  color: #151515;
}
.css-1n7v3ny-option:active {
  background-color: #e7e1c5 !important ;
}
.css-1n7v3ny-option {
  background-color: #e7e1c5 !important;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
.customSelect .css-1uccc91-singleValue {
  color: white;
}
.customSelect .css-26l3qy-menu {
  background-color: #151515;
  margin-left: 3% !important;
  color: #e7e1c5;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.css-9gakcf-option {
  background-color: #c57a1e !important;
  color: hsl(0, 0%, 100%);
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
.css-9gakcf-option:active {
  background-color: #c57a1e !important;
}
.customSelect .css-b8ldur-Input {
  color: white;
}

.css-yk16xz-control {
  height: 48px;
  /* ^^ That sets the height of TeamName text field. */
}

/* ********************* */
.btn-add-team {
  position: absolute;
  left: 29vw;
}

@media (max-height: 724px) {
  /* For 0px to 724px */
  .btn-add-team {
    /* top: 32vh; */
    /* bottom: 10vh; */
  }
}

@media (min-height: 725px) {
  .btn-add-team {
    /* top: 42vh; */
  }
}

/* ********************* */
/* `cancel` and `delete` button styles in popup dialog in <team credentials page> in <Delete team> */
/* FYI: Iphone5's height is 568px. */
@media (max-width: 724px) {
  /* For 0px to 724px */
  .btn-action {
    width: 150px;
  }
}

/* 320px is iphone5 */
@media (max-width: 380px) {
  .btn-action {
    width: 120px;
  }
}

.css-4ljt47-MenuList {
  margin-left: 0;
}

.css-26l3qy-menu {
  margin: 0;
}

.customSelect .css-26l3qy-menu {
  /* margin-left: 3% !important; */
  margin-left: 0 !important;
}

/* .css-1okebmr-indicatorSeparator {
  / Tried to remove the vertical line in react-select field, but it doesn't seem to change anything. :( , 11 Jun, 2021.
  background-color: none;
} */

/* *****trying out transition... */
/* src: https://stackoverflow.com/a/12638711 */
@keyframes slideInFromLeft {
  0% {
    /* transform: translateX(-100%); */
    opacity: 0;
  }
  100% {
    opacity: 1;
    /* transform: translateX(0); */
  }
}

.team-credentials-items {
  /* This section calls the slideInFromLeft animation we defined above */
  animation: 1s ease-out 0s 1 slideInFromLeft;

  background: #333;
  padding: 30px;
}
/* *****trying out transition... */
