/* .navigation
{
    floatl: "left",
    alignItems: "center",
    color: "#C57A1E",
    marginTop: "30px",
    fontFamily: "Atten New",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "18px",
  },
  naviSales: {
    color: "#ffffff",
  },
  indicator: {
    borderBottom: "solid 2px #BC936B",
  /* }, */
/* .navigation {
  font-family: Atten New;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #c57a1e;
}
.navigation ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.navigation ul li {
  display: inline;
  list-style: none;
} */

/* .PrivateTabIndicator-colorSecondary-12 {
  color: #bc936b !important;
}
.MuiTabs-indicator {
  color: #bc936b !important;
}

.PrivateTabIndicator-root-10 {
  color: #bc936b !important;
} */
body {
  width: '1440px';
}
.MuiPaper-root {
  background-color: #0f0f0f ;

  /* #151515 This is very dark background in the whole app. */
}
