.LoginView {
  width: 100%;
  height: 100vh;
}

.LoginView .LoadingHeader .back-icon {
  margin-right: 22px;
}
.loading-screen-listing-delisting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.LoginView .LoadingHeader .loader-line {
  height: 4px;
  background: #e7e1c5;
  z-index: 2;
  position: absolute;
}
.LoginView .LoadingHeader .track-line {
  position: absolute;
  height: 1px;
  background: rgba(255, 255, 255, 0.11);
  width: 100%;
  z-index: 1;
}

.LoginView :is(.email-div, .otp-div) {
  margin: auto;
  width: 70%;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.LoginView .email-div .login-text {
  font-weight: bold;
  color: #bc936b;
}

.LoginView .default-button.desktop {
  width: 200px;
}

.LoginView .email-div.mobile-layout {
  width: 100%;
  padding: 20px;
  padding-bottom: 50px;
}

.LoginView .email-div.mobile-layout .login-text {
  margin-right: auto;
}

.LoginView .email-div.mobile-layout .default-button {
  margin: auto;
}
.LoginView .sign-up-text {
  color: white;
  font-size: 14px;
  margin-top: 45px;
  cursor: pointer;
}
.LoginView .sign-up-text .sign-up {
  color: #bc936b;
  cursor: pointer;
}
.LoginView .sign-up-text .sign-up {
  color: #bc936b;
  cursor: pointer;
}

.LoginView .otp-dets-div {
  z-index: 1;
  font-size: 24px;
}
.LoginView .otp-div img {
  width: 100%;
  max-height: 375px;
  max-width: 508px;
  object-fit: cover;
  position: absolute;
}
.LoginView .otp-div .enter-code-div {
  color: #e7e1c5;
}
.LoginView .otp-div .desc-div {
  color: #e7e1c5;
  color: white;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 22px;
}
.LoginView .otp-div .otp-dets-div {
  position: absolute;
  bottom: 18%;
}
.LoginView .otp-div .otp-dets-div button {
  margin-top: 30px;
}
