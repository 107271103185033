.SwipeableView .swipeable-content-box {
	background: #010101;
	width: 100%;
	height: 100%;
}

.SwipeableView .title-label {
	font-weight: 800;
	font-size: 30px;
	color: #c8a178;
	margin-top: 71px;
	margin-bottom: 16px;
}
.SwipeableView .detail-label {
	font-weight: normal;
	font-size: 18px;
	color: white;
}

.SwipeableView img {
	width: 217px;
	height: 164px;
}

.SwipeableView {
	background: rgba(1, 1, 1, 0.7);
}

.SwipeableView .action-button {
	font-weight: bold;
	font-size: 16px;
	color: #bc936b;
	cursor: pointer;
}

.SwipeableView .action-button.skip {
	color: #e7e1c5;
}

.SwipeableView.desktop .swipeable-content-box {
	width: 541px;
	height: 560px;
	background: #010101;
	box-shadow: 0px 4px 40px rgba(231, 225, 197, 0.1);
	border-radius: 12px;
}
