.Home {
	position: relative;
	width: 100%;
}

.Home .home-content {
	width: 90%;
}

.Home .TotalsView {
	width: 100%;
}

.Home .ticket-search-div {
	margin: 33px 0;
}

.Home .ticket-search-div .title-div {
	color: #ffffff;
	font-size: 34px;
}

.Home .TicketCard {
	margin-bottom: 30px;
}

.home-swipeable-view {
	top: 0;
	left: 0;
  z-index: 1301;
}
