.MuiDialog-container {
	/* height: 900px; */
	/* height: 900px !important; */
	/* ^^ Improved code coz giving height to anything in css is like poison! ~sahil, also fixed the unnecessary height issue in mobile screen as well. */
}

.MuiDialog-scrollPaper {
	display: block !important;
	/* position: absolute; */
	/* ^^ Commented this to get full width of the dashboardpopup dialog box, 15 Jun, 21 */
	/* left: 15%; */
	/* ^^COMMENTED coz we want to center elements in FullscreenPopup ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎ */
}
@media only screen and (max-width: 600px) {
	.MuiDialog-scrollPaper {
		display: block !important;
		position: absolute;
		/* left: -58px; */
		/* ^^COMMENTED coz we want to center elements in FullscreenPopup ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎ */
	}
}
.card {
	background-color: #010101 !important;
	border: none;
}
.MuiDialogActions-root {
	padding: 0 !important;
}
.card {
	background-color: #010101 !important;
	border: none;
}
@media only screen and (max-width: 500px) {
	.MuiDialog-paperFullScreen {
		overflow: auto;
	}
}

.loading-screen-listing-delisting {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.title1 {
	font-size: 18px;
	color: #e7e1c5;
	font-weight: 700;
	font-family: Atten New;
	font-style: normal;
	text-transform: uppercase;
	padding: 9px 0;
}

.knicks {
	font-size: 24px;
	color: #bc936b;
	font-family: Atten New;
	font-style: normal;
	font-weight: 400;
	/* margin-top: 19px; */
	text-transform: uppercase;
	padding: 9px 0;
}

.vs {
	font-weight: bold;
	font-size: 20px;
	color: #ffffff;
	font-family: Atten New;
}

.huston {
	font-size: 18px;
	color: #e7e1c5;
	font-weight: 700;
	font-family: Atten New;
	font-style: normal;
	text-transform: uppercase;
}

.rockets {
	font-size: 24px;
	color: #bc936b;
	font-weight: 400;
	font-family: Atten New;
	font-style: normal;
	text-align: center;
	font-weight: 0;
	text-transform: uppercase;
	padding: 9px 0;
}

.my-simple-flex {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	/* outline: 4px solid red; */
	position: relative;
	justify-items: space-between;
	margin: 0 auto;
	z-index: 1;
	-webkit-transform: translate3d(0, 0, 0);
	/* wow what a magic ^^ now this works for safari: src : https://stackoverflow.com/a/40896165 */
}

.my-simple-flex--child {
	/* d-flex flex-column */
	display: flex;
	flex-direction: column;
	/* outline: 1px solid deeppink; */
	width: 100%;
}

.background-img-sports {
	margin: 0 auto;
	position: absolute;
	/* position: relative; */
	width: 339px;
	height: 189px;
	/* outline: 3px solid orange; */
	/* z-index: 5000; */
	/* ^^ Undid this to fix the issue with background image getting in front of other texts. */
}

.popup-dates-1 {
	color: #ffffff;
	height: 21px;
	font-size: 18px;
	line-height: 21px;
}
