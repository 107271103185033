/* .MuiPaper-root
  .MuiDialog-paper
  .MuiDialog-paperScrollPaper
  .MuiDialog-paperWidthSm
  .MuiPaper-elevation24
  .MuiPaper-rounded {
  left: 23%;
}
.MuiDialog-paperWidthSm {
  position: relative;
  left: 23%;
}
@media only screen and (max-width: 600px) {
  .MuiDialog-paperWidthSm {
    left: 9%;
    bottom: 10px;
  }
} */
/* .MuiDialog-container {
  position: relative;
  left: 25%;
}

@media only screen and (max-width: 600px) {
  .MuiDialog-container {
    left: 9%;
    bottom: 10px;
  }
} */

/* style={{
  marginLeft: isMobile ? "58px !important" : "60% !important",
  marginRight: isMobile ? "0px !important" : "0px !important",
  marginBottom: isMobile ? "" : "66px !important",
  marginTop: isMobile ? "-45px" : "-5px",
  boxShadow: "-2px 4px 40px rgba(255, 255, 255, 0.25) !important",
}} */

/* .Dialog {
  margin-left: "60% !important";
  margin-right: 0px !important;
  box-shadow: "-2px 4px 40px rgba(255, 255, 255, 0.25) !important";
  margin-top: "-5px";
} */
/* .MuiDialog-scrollPaper {
  margin-left: -41px;
}

@media only screen and (max-width: 600px) {
  .MuiDialog-scrollPaper {
    margin-left: -41px;
  }
} */

.MuiDialog-paper {
  /* margin: 32px; */
  /* position: fixed !important; */
  /* ~sahil, ^^ COMMENTED  coz we want scroll-able fullscreen dialog */
  /* margin-left: 59px !important; */
  /* ~sahil, ^^ COMMENTED  coz we don't want content to overflow... */
  /* margin-top: 36px; */
  overflow-y: inherit !important;
}

/* **** ~sahil, below code */

@media (max-width: 428px) {
  /* For 0px to 428px, as 428px is the width of 'Iphone12 Pro Max' */
  .profile-page {
    right: 0;
    border-radius: 0;
  }
}

@media (min-width: 429px) {
  /* ^^ That says for 429px to infinity. */
  /* For 0px to 428px, as 428px is the width of 'Iphone12 Pro Max' */
  .profile-page {
    right: 30;
    border-radius: 30;
  }
}
