.PrivateTabIndicator-colorSecondary-35 {
  background-color: #c57a1e !important;
}

.PrivateTabIndicator-colorSecondary-39 .PrivateTabIndicator-colorSecondary-37 .PrivateTabIndicator-colorSecondary-38 {
  background-color: #c57a1e !important;
}
.PrivateTabIndicator-colorSecondary-39 {
  background-color: #c57a1e !important;
}
.PrivateTabIndicator-colorSecondary-40 {
  background-color: #c57a1e !important;
}
.PrivateTabIndicator-colorSecondary-19 {
  background-color: #c57a1e !important;
}
.PrivateTabIndicator-colorSecondary-41 {
  background-color: #c57a1e !important;
}
.PrivateTabIndicator-colorSecondary-42 {
  background-color: #c57a1e !important;
}

.PrivateTabIndicator-colorSecondary-44 {
  background-color: #c57a1e !important;
  width: 41px !important;
  margin-left: 15px;
  color: #c57a1e;
}
.MuiTab-textColorInherit {
  opacity: 1 !important;
}
.body {
  color: none !important;
}
.MuiAppBar-colorPrimary {
  background: '#010101' !important;
}

@media (max-width: 428px) {
  /* For 0px to 428px, as 428px is the width of 'Iphone12 Pro Max' */
  .mobile-navigation-bar {
    display: block;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1300;
    /* transition: visibility ease-in 300ms; */

    /* If you give z-index 1301 or more, it'll come over the FullScreenPopUp thus, 1300 is exactly what we need. */
  }
}

@media (min-width: 429px) {
  /* ^^ That says for 429px to infinity. */
  /* For 0px to 428px, as 428px is the width of 'Iphone12 Pro Max' */
  .mobile-navigation-bar {
    display: none;
  }
}
